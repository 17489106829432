import * as React from "react";
import { softShadows, OrbitControls, useProgress, Html, CameraShake } from "@react-three/drei";
import { EffectComposer, Bloom, Noise, Vignette, DepthOfField } from "@react-three/postprocessing";
import { VRCanvas } from '@react-three/xr';
import Model from "@components/Model";
import "./index.scss";
import Scene from "@components/Scene";
import { useFrame, useThree } from "@react-three/fiber";
import { useParams } from "react-router-dom";

softShadows({
  frustum: 3.75,
  size: 0.005,
  near: 9.5,
  samples: 17,
  rings: 11,
})

const Loader = () => {
  const { progress } = useProgress();
  return <Html center>{'Initializing ' + progress + '%'}</Html>;
};

function WobbleCamera() {
  const { camera, gl: { domElement }} = useThree();
  const shakeRef:any = React.useRef();
  const controls:any = React.useRef();
  const config: any = {
    maxYaw: 0.01,
    maxPitch: 0.01,
    maxRoll: 0.01,
    yawFrequency: 0.1,
    pitchFrequency: 0.1,
    rollFrequency: 0.1,
    intensity: 1,
    decayRate: 0.2,
    controls: controls
  };
  
  useFrame(() => controls.current.update());

  return (
    <>
      <OrbitControls
        ref={controls}
        args={[camera, domElement]}
        enableDamping
        autoRotate={true}
        autoRotateSpeed={0.01}
        enableZoom={false}
        enablePan={false}
        // maxDistance={18}
        // maxZoom={18}
        // minDistance={18}
        // minZoom={18}
      />
      <CameraShake {...config} ref={shakeRef} />
    </>
  );
}

const Index = () => {
  let params = useParams();
  const spinRunner = true;
  const audioOn = true;
  const runnerId = params.runnerId;

  return (
    <div id="canvas-container">
      <VRCanvas shadows camera={{ position: [0, -3, 140], fov: 20 }} gl={{ alpha: true, powerPreference: "high-performance" }} vr>
        <React.Suspense fallback={<Loader />}>
          <ambientLight intensity={0.5} />
          <directionalLight castShadow position={[0, 10, 0]} intensity={1.5} />
          <Model file={`/${runnerId}.gltf`} spinRunner={spinRunner} audioOn={audioOn} />
          <EffectComposer>
            <DepthOfField focusDistance={0} focalLength={4} bokehScale={0.4} height={40} />
            <Bloom luminanceThreshold={0.8} luminanceSmoothing={1} height={400} />
            <Noise opacity={0.15} />
            <Vignette eskil={false} offset={0.1} darkness={0.5} />
          </EffectComposer>
          <Scene hdr="keyart_wide.hdr" />
          <OrbitControls />
          <WobbleCamera />
        </React.Suspense>
      </VRCanvas>
    </div>
  );
};

export default Index;
