import * as THREE from "three";
import React, { useRef, useState } from "react";
import { useGLTF, Bounds } from "@react-three/drei";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
// import { mergeBufferGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { useFrame } from "@react-three/fiber";
import Audio from "@components/Audio";
import { Mesh } from "three";

type GLTFResult = GLTF & {
  // nodes: {
  //   "Cube.1236": THREE.Mesh;
  // };
  nodes: any;
  materials: any;
};

interface ModelProps {
  file: string;
  spinRunner: boolean;
  audioOn: boolean;
}

export default function Model(props: ModelProps) {
  const [hovered, setHover] = useState(false)
  const mesh: any = React.useRef();
  const group: any = useRef<THREE.Group>();
  const { nodes } = useGLTF(props.file) as GLTFResult;
  if (hovered) console.log('Focus Initiated');

  useFrame(() => group.current.rotation.y = group.current.rotation.y += 0.003);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
      onPointerOver={e => setHover(true)}
      onPointerOut={e => setHover(false)}
    >
      <Bounds fit clip damping={4} margin={1.4}>
        {Object.entries(nodes).map((node:any, i: number) => {
          if(node[1] instanceof Mesh) {
            return (
              <mesh
                key={i}
                castShadow
                receiveShadow
                geometry={node[1].geometry}
                material={node[1].material}
                position={[0, 0, 0]}
                ref={mesh}
              />
            )
          }
          return null;
        })}
        <Audio audioOn={props.audioOn} url="./rumble.mp3" />
      </Bounds>
    </group>
  );
}

