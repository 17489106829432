import "@app/App.scss";
import Index from "@pages/index";
import Runner from "@pages/runner";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path=":runnerId" element={<Runner />} />
      <Route path="*" element={<Index />} />
    </Routes>
  );
}

export default App;
