import { Environment } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';

interface SceneProps {
  hdr: string;
  path?: string;
}

const Scene: React.FC<SceneProps> = ({ hdr, path = "/" }) => {
  const mesh: any = useRef();
  const group = useRef<THREE.Group>();
  useFrame(() => {});

  return (
    <group ref={group}>
      <mesh ref={mesh} >
        <Environment background={true} path={path} files={hdr} />
      </mesh>
    </group>
  )
};

export default Scene;
