import * as THREE from 'three'
import { useLoader, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";

interface AudioProps {
  url: string;
  audioOn: boolean;
}

const Audio: React.FC<AudioProps> = ({ url, audioOn }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const audioCtx = new AudioContext();
  const sound: any = useRef()
  const { camera } = useThree()
  const [listener] = useState(() => new THREE.AudioListener())
  const buffer = useLoader(THREE.AudioLoader, url)

  const pauseSound = () => sound.current.pause()
  const playSound = () => sound.current.play()
  const stopSound = () => sound.current.stop()
  
  useEffect((): any => {
    if(audioCtx) {
      audioCtx.resume();
    }
    return () => audioCtx.suspend();
  }, [audioCtx])

  useEffect((): any => {
    sound.current.setBuffer(buffer)
    sound.current.setRefDistance(1)
    sound.current.setLoop(true)
    camera.add(listener)
    return () => camera.remove(listener)
  }, [buffer, camera, listener])

  useEffect((): any => {
    if (audioOn) {
      playSound()
    } else {
      pauseSound()
    }
    return () => stopSound()
  }, [audioOn])

  return <positionalAudio ref={sound} args={[listener]} />
};

export default Audio;
