import "./index.scss";

const Index = () => {
  return (
    <div id="canvas-container">
      Runner Not Found
    </div>
  );
};

export default Index;
